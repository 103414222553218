#navbar {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 20vh;
    padding: 0 2rem;
    z-index: 1000;
    background-color: #FDF4F5;
}

#navbar ul li {
    font-size: 2rem;
    font-family: 'Gruppo', sans-serif;
    text-transform: uppercase;
}

#logo {
    height: 80%;
    cursor: url(./images/pizza-cursor-hover.png), auto;
}

.link {
    color: black;
    text-decoration: none;
}

.link :hover {
    color: #BA90C6;
    cursor: url(./images/pizza-cursor-hover.png), auto;
}

@media (orientation: landscape) {

    #navbar ul {
        display: flex;
        justify-content: space-between;
        gap: 4rem;
    }

    .burger {
        display: none;
    }

}

@media (orientation: portrait) {

    .burger {
        display: flex;
        width: 5rem;
        height: 5rem;
        background-color: transparent;
        border: none;
    }

    .burger img {
        width: 100%;
        object-fit: cover;
    }

    #navbar ul {
        display: none;
    }

    #hidden {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80vh;
        background-color: #FDF4F5;
        z-index: 1000;
    }

    #hidden li {
        font-size: 5rem;
        font-family: 'Gruppo', sans-serif;
        color: #BA90C6;
        line-height: 10rem;
        text-transform: uppercase;
        text-align: center;
    }

}