#ingredients {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}


#ingredient-card p {
    font-size: 2.5rem;
    font-family: 'Gruppo', sans-serif;
    line-height: 4rem;
    color: black;
}

@media (orientation:landscape) {
    #ingredients {
        width: 100%;
        height: 180vh;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    #ingredient-card {
        width: 90%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    #ingredient-card img {
        width: 30%;
        aspect-ratio: 2/1;
        border-radius: 3rem;
    }

    #ingredient-card p {
        width: 60%;
    }
}

@media (orientation: portrait) {

    #ingredient-card {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    
    #ingredient-card img {
        width: 100%;
        border-radius: 2rem;
        margin: 4rem 0;
    }

}