
#contact {
    background-color: #C0DBEA;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 10vh;
}

.contact-info {
    width: 25%;
}

.contact-info-section {
    display: flex;
    align-items: center;
    padding: 2rem 0;
}

.contact-image {
    width: 4rem;
    margin-right: 1rem;
}

.contact-info-text {
    color: #BA90C6;
    font-size: 1.5rem;
    font-weight: 400;
}
/* FORMA */

.contact-form {
    width: 51%;
}

.form-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.form-row:not(:last-of-type) {
    margin-bottom: 3rem;
}

input:not([type='submit']):not([type='button']), textarea {
    line-height: 3rem;
    font-size: 1.7rem;
    background: transparent;
    border: 0;
    border-bottom: 1px solid currentColor;
    cursor: url(./images/pizza-cursor-hover.png), auto;
}

textarea {
    resize: none;
    overflow: auto;
    height: 7rem;
}

input, button, textarea {
    border-radius: 0;
    -webkit-tap-highlight-color: transparent;
    color: #BA90C6;
    outline: none;
}

input, textarea {
    writing-mode: horizontal-tb !important;
}

.input-group {
    color: rgb(186, 144, 198, 0.5);
    display: flex;
    flex-direction: column;
}

.input-group label {
    margin-bottom: 0.8rem;
    font-weight: 200;
    font-size: 1.5rem;
}

.personal-info {
    width: 47%;
}

.message {
    width: 100%;
}

.send-button {
    width: 50%;
    height: 5rem;
    font-size: 2rem;
    color: #BA90C6;
    text-transform: uppercase;
    background: transparent;
    background-color: transparent;
    border-color: #BA90C6;
    margin-top: 5rem;
    transform: translate(50%, 0);
    transition: transform 0.3s, background-color 0.3s, color 0.3s;
    cursor: url(./images/pizza-cursor-hover.png), auto;
}
.send-button:hover {
    transform: translate(50%, -10%);
    background-color: #BA90C6;
    color: #C0DBEA;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

@media (orientation: landscape) and (max-width: 992px) {

    .contact-image {
        width: 2.8rem;
    }

    .contact-info-text {
        font-size: 1.1rem;
    }


}

@media (orientation: landscape) and (max-width: 768px) {

    .contact-image {
        width: 2.3rem;
    }

    .contact-info-text {
        font-size: 0.9rem;
    }

    input:not([type='submit']):not([type='button']), textarea {
        line-height: 2;     /* ???????????????? */
        font-size: 1.3rem;
       
    }

    .input-group label {
        margin-bottom: 0.6rem;
        font-size: 1rem;
    }

    textarea {
        height: 5rem;
    }
    

    .send-button {
        height: 3.5rem;
        font-size: 1.5rem;
    }
}

@media (orientation: portrait) {

    #contact {
        flex-direction: column-reverse;
        border-radius: 0;
        justify-content: space-around;
    }

    .contact-form {
        width: 90%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .form-row {
        width: 100%;
        flex-direction: column;
    }

    .personal-info {
        width: 100%;
    }

    input:not([type='submit']):not([type='button']), textarea {
        line-height: 4rem;
        font-size: 3rem;
       
    }

    .input-group label {
        margin-bottom: 0.6rem;
        font-size: 2rem;
    }

    .personal-info:nth-child(1) {
        margin-bottom: 3rem;
    }

    .contact-info {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        height: 20vh;
    }

    .contact-image {
        width: 10%;
        padding: 0;
    }

    .contact-info-section {
        display: flex;
        justify-content: center;
        width: 95%;
        padding: 0 0;
    }

    .send-button {
        margin-top: 0;
    }

}

@media (orientation: portrait) and (max-width: 768px)  {

    #contact {
        border-radius: 0;
    }

}


@media (orientation: portrait)  and (max-width: 480px) {

    input:not([type='submit']):not([type='button']), textarea {
        line-height: 3rem;
        font-size: 2rem;
       
    }

    .input-group label {
        margin-bottom: 0.6rem;
        font-size: 1.5rem;
    }

    .contact-info-text {
        font-size: 1rem;
    }
    

}

@media (max-width:400px) {

    input:not([type='submit']):not([type='button']), textarea {
        line-height: 2rem;
        font-size: 1rem;
       
    }

    .input-group label {
        margin-bottom: 0.3rem;
        font-size: 0.9rem;
    }

    textarea {
        height: 4rem;
    }

    .send-button {
        font-size: 1.2rem;
        height: 2.5rem;
    }

}